<template>
  <div>
    <hero-bar :has-right-visible="false"> ໜ້າຫຼັກ </hero-bar>
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
          <button
            class="button is-primary is-fullwidth is-large"
            @click="goto('addshipment')"
          >
            ຮັບຝາກເຄື່ອງ
          </button>
        </div>
        <div class="column">
          <button
            class="button is-success is-fullwidth is-large"
            @click="goto('toDeliverParcels')"
          >
            ເອົາເຄື່ອງໃຫ້ລູກຄ້າ
          </button>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <card-widget
            class="tile is-child"
            type="is-success"
            icon="chart-timeline-variant"
            :number="shipments"
            label="ການຂົນສົ່ງ"
          />
        </div>
        <div class="column">
          <card-widget
            class="tile is-child"
            type="is-primary"
            icon="account-multiple"
            :number="customers"
            label="ລູກຄ້າ"
          />
        </div>
        <div class="column">
          <card-widget
            class="tile is-child"
            type="is-info"
            icon="cart-outline"
            :number="branches"
            prefix=""
            label="ສາຂາ"
          />
        </div>
        <div class="column">
          <div class="card" style="padding: 1.5em">
            <router-link to="exchangerates">
              <div class="has-text-centered">
                <country-flag :country="'th'" size="big" />
              </div>
              <div class="has-text-centered" style="font-size: 1.5em">
                {{ exchangerate["THB"] | formatnumber }} ກີບ
              </div>
            </router-link>
          </div>
        </div>
        <div class="column">
          <div class="card" style="padding: 1.5em">
            <router-link to="exchangerates">
              <div class="has-text-centered">
                <country-flag :country="'us'" size="big" />
              </div>
              <div class="has-text-centered" style="font-size: 1.5em">
                {{ exchangerate["USD"] | formatnumber }} ກີບ
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <card-component
        title="ການຂົນສົ່ງ"
        icon="truck"
        header-icon="reload"
        @header-icon-click="loadShipChart"
      >
        <div v-if="shipmentChart.chartData" class="chart-area">
          <line-chart
            ref="bigChart"
            style="height: 100%"
            chart-id="shipment-line-chart"
            :chart-data="shipmentChart.chartData"
            :extra-options="shipmentChart.extraOptions"
          >
          </line-chart>
        </div>
      </card-component>

      <card-component
        title="COD"
        icon="cash"
        header-icon="reload"
        @header-icon-click="loadCodChart"
      >
        <div v-if="codChart.chartData" class="chart-area">
          <line-chart
            ref="bigChart"
            style="height: 100%"
            chart-id="cod-line-chart"
            :chart-data="codChart.chartData"
            :extra-options="codChart.extraOptions"
          >
          </line-chart>
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import * as chartConfig from "@/components/Charts/chart.config";
import HeroBar from "@/components/HeroBar";
import CardWidget from "@/components/CardWidget";
import CardComponent from "@/components/CardComponent";
import LineChart from "@/components/Charts/LineChart";
import axios from "axios";
import { get } from "vuex-pathify";
//var  AndroidApp;
export default {
  name: "Home",
  components: {
    LineChart,
    CardComponent,
    CardWidget,
    HeroBar,
  },
  data() {
    return {
      branches: 0,
      customers: 0,
      shipments: 0,
      shipmentChart: {
        chartData: null,
        extraOptions: chartConfig.chartOptionsMain,
      },
      codChart: {
        chartData: null,
        extraOptions: chartConfig.chartOptionsMain,
      },
      stat: null,
    };
  },
  computed: {
    titleStack() {
      return [""];
    },
    exchangerate: get("exchangerate"),
  },
  mounted() {
    axios.get("dashboard").then((r) => {
      this.branches = r.branches;
      this.customers = r.customers;
      this.shipments = r.shipments;
      this.stat = r.stat;
      this.loadShipChart();
      this.loadCodChart();
    });

    this.$buefy.snackbar.open({
      message: "ຍິນດີຕ້ອນຮັບ  " + (this.isAndroid ? "(App)" : ""),
      queue: false,
    });

    console.log(this.isAndroid);
  },
  methods: {
    goto(route) {
      this.$router.push(route);
    },
    loadShipChart() {
      const shipmentSum = this._.map(this.stat["shipment"], (item) => item.tx);
      const shipmentTx = this._.map(this.stat["shipment"], (item) => item.tx);
      const shipmentLabel = this._.map(this.stat["shipment"], (s) => s.txdate);

      this.shipmentChart.chartData = {
        datasets: [
          {
            fill: true,
            borderColor: chartConfig.chartColors.default.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: shipmentSum,
          },
          {
            fill: false,
            borderColor: chartConfig.chartColors.default.info,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: shipmentTx,
          },
        ],
        labels: shipmentLabel,
      };
    },

    loadCodChart() {
      const codSum = this._.map(this.stat["cod"], (item) => item.sum);
      const codTx = this._.map(this.stat["cod"], (item) => item.tx);
      const codLabel = this._.map(this.stat["cod"], (s) => s.txdate);

      this.codChart.chartData = {
        datasets: [
          {
            fill: true,
            borderColor: chartConfig.chartColors.default.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: codSum,
          },
          {
            fill: false,
            borderColor: chartConfig.chartColors.default.info,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: chartConfig.chartColors.default.info,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: codTx,
          },
        ],
        labels: codLabel,
      };
    },
  },
};
</script>
